import Vue from "vue";

/*TITLE*/
document.title = "Residencial Real 230 | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Residencial Real 230";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "Residencial Real 230";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-forest-natural--20230126110105.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "master-bath-bottega-caliza-marbella-stone-forest-natural--20230126110154.jpg";
Vue.prototype.$image_bath2 = "banyo-auxiliar-bottega-caliza-marbella-stone-forest-natural--20230126110127.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-forest-natural--20230126110105.jpg",
  },
  {
    src: "salon-forest-acero--20230126110115.jpg",
  },
  {
    src: "salon-bottega-caliza-60x60--20230126110123.jpg",
  },
  {
    src: "salon-bottega-acero-60x60--20230126110131.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "master-bath-bottega-caliza-marbella-stone-forest-natural--20230126110154.jpg",
  },
  {
    src: "master-bath-bottega-caliza-spiga--20230126110104.jpg",
  },
  {
    src: "master-bath-bottega-acero-marbella-stone-forest-acero--20230126110115.jpg",
  },
  {
    src: "master-bath-bottega-acero-spiga--20230126110121.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo-auxiliar-bottega-caliza-marbella-stone-forest-natural--20230126110127.jpg",
  },
  {
    src: "banyo-auxiliar-bottega-acero-marbella-stone-forest-acero--20230126110152.jpg",
  },
  {
    src: "auxiliar-bath-bottega-caliza-spiga--20230126110103.jpg",
  },
  {
    src: "auxiliar-bath-bottega-acero-spiga--20230126110109.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "6";
Vue.prototype.$gallery_grid_bath2 = "6";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = false;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20real%20230%20cádiz%2011100",
    text: "Calle Real 230, Cádiz, 11100",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20real%20230%20cádiz%2011100",
    text: "Calle Real 230, Cádiz, 11100",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:603527712",
    text: "603527712",
  },
  {
    icon: "mdi-email",
    link: "mailto:contacto@bahiagadir.com",
    text: "contacto@bahiagadir.com",
  },
];
